.weekhead {
  position: relative;
  width: 12.5%;
  min-height: 180px;
  padding: 13px 10px;
  background-color: #ECE9E9;
}

.weekhead:before {
  content: '';
  position: absolute;
  left: -10px;
  top: 0;
  height: 1px;
  width: calc(100% + 10px);
  background: linear-gradient(0.25turn,#D1D1D1, #ECE9E9);
}

.emptyWeek {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 0;
  height: 100%;
  color: #909090;
  font-size: .75rem;
}

.title {
  font-size: .75rem;
  font-weight: 500;
  padding: 0 0 2px;
}

.progress {
  padding: 10px 0;
}

.mark {
  display: flex;
  justify-content: space-between;
}

.markName, .markValue {
  font-size: .675rem;
  padding: 2px 0;
}

.progressBar {
  padding: 5px 0;
  height: 26px;
}

.progressBar svg {
  width: 100%;
  max-height: 5px;
}