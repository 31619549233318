.logo {
  display: flex;
  justify-content: center;
  text-shadow: 0 2px 6px rgba(0, 0, 0, 0.25);
}

.addon {
  font-size: x-large;
  color: #FFF;
  margin: -8px 0 0 10px;
}