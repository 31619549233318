@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap');
@import-normalize;
* {
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
}

*:focus {
    outline: none;
}

a {
    text-decoration: none;
}

a:visited {
    color: rgb(0, 102, 204)
}

a:hover {
    text-decoration: underline;
}

ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

span {
    display: block;
}

body {
    margin: 0;
    font-family: 'Roboto', sans-serif;
    position: relative;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

input,
button,
textarea {
    font-family: 'Roboto', sans-serif;
}

.ReactModal__Html--open {
    overflow: hidden;
}

.container {
    max-width: 1200px;
    margin: 0 auto;
    height: 100vh;
    position: relative;
}

.cover__image {
    background-image: url(./assets/img/bg_compressed.jpg);
    background-position: center top;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
}