.tooltip {
  background-color: #FFFFFF;
  padding: 3px 12px;
  border: 1px solid #D8D8D8;
}

.label {
  margin: 7px 0;
  font-size: .875rem;
}

.label span {
  display: inline-block;
  font-weight: bold;
}