.closeBtn {
  cursor: pointer;
  font-size: 2rem;
  background: none;
  border: none;
  padding: 0;
  line-height: .7;
}

.closeBtn:hover {
  color: #535353;
}

.closeBtn:active {
  transform: scale(.95);
}

.subtitle {
  font-size: .875rem;
  color: #444444;
  margin: 0;
  padding: 4px 0 8px;
}