.top {
  width: 100%;
  background-color: #FFFFFF;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.08);
}

.modalOpen {
  padding-right: 17px;
}

.headline {
  padding: 8px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: auto;
  white-space: nowrap;
}

.left, .right {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.left {
  max-width: 300px;
}

.monthSelector {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.prevMonth, .nextMonth {
  cursor: pointer;
}

.prevMonth:before, .nextMonth:before {
  content: '';
  border-width: 0.15em 0.15em 0 0;
  display: inline-block;
  border-style: solid;
  height: 0.4rem;
  transform: rotate(-135deg);
  width: 0.4rem;
  margin: 1px 5px;
  transition: all .15s ease;
}

.nextMonth:before {
  transform: rotate(45deg);
}

.prevMonth:hover:before {
  transform: scale(1.1) rotate(-135deg);
}

.nextMonth:hover:before {
  transform: scale(1.1) rotate(45deg);
}

.prevMonth:active:before {
  transform: scale(.9) rotate(-135deg);
}

.nextMonth:active:before {
  transform: scale(.9) rotate(45deg);
}

.currentMonth {
  min-width: 100px;
  display: flex;
  justify-content: center;
  font-size: .875rem;
  font-weight: 500;
}

.currentMonth p {
  margin: 0;
  padding: 0;
}

.currentMonth p:first-letter {
  text-transform: uppercase;
}

.topBtn {
  height: 28px;
  padding: 0 16px;
  font-size: .75rem;
  margin: 0 25px;
}

.currentProgram, .eventBlockItem {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 28px;
  padding: 0 10px;
}

.event {
  display: flex;
}

.eventBlockItem:last-child {
  padding-right: 0;
}

.currentProgram p, .eventBlockItem p{
  font-size: .625rem;
  font-weight: 500;
  text-transform: uppercase;
  color: #737373;
  padding: 0;
  margin: 0;
}

.currentProgram span, .eventBlockItem span {
  font-size: .75rem;
  font-weight: bold;
}

.weekline {
  border-top: 1px solid #E0E0E0;
}

.weeklineList {
  display: flex;
}

.weeklineListItem {
  width: 12.5%;
  padding: 10px 0 10px 15px;
  text-transform: uppercase;
  font-size: .625rem;
  color: #909090;
}


@media (max-width: 576px) {
  .topBtn {
    margin: 0 16px;
  }

  .topBtn:first-child {
    margin-left: 0;
  }

  .currentProgram {
    margin-right: 15px;
  }
}