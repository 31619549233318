.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 100px 0 30px;
}

.logo {
  padding: 20px 25px;
}

.bottom {
  width: 450px;
  font-size: .75rem;
  margin: 0;
  color: #FFFFFF;
  line-height: 1.4;
}

.paragraph {
  text-align: center;
}

.link {
  font-weight: 500;
  padding: 1px 0;
  color: #F3F3F3;
  border-bottom: 1px solid;
}

.link:hover {
  color: #FFFFFF;
  text-decoration: none;
  border-bottom: 1px solid transparent;
}
