@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap);
/* Document
 * ========================================================================== */
/**
 * 1. Correct the line height in all browsers.
 * 2. Prevent adjustments of font size after orientation changes in
 *    IE on Windows Phone and in iOS.
 */
html {
  line-height: 1.15; /* 1 */
  -ms-text-size-adjust: 100%; /* 2 */
  -webkit-text-size-adjust: 100%; /* 2 */
}
/* Sections
 * ========================================================================== */
/**
 * Correct the font size and margin on `h1` elements within `section` and
 * `article` contexts in Chrome, Edge, Firefox, and Safari.
 */
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}
/* Grouping content
 * ========================================================================== */
/**
 * Remove the margin on nested lists in Chrome, Edge, IE, and Safari.
 */
dl dl,
dl ol,
dl ul,
ol dl,
ul dl {
  margin: 0;
}
/**
 * Remove the margin on nested lists in Edge 18- and IE.
 */
ol ol,
ol ul,
ul ol,
ul ul {
  margin: 0;
}
/**
 * 1. Add the correct box sizing in Firefox.
 * 2. Show the overflow in Edge 18- and IE.
 */
hr {
  box-sizing: content-box; /* 1 */
  height: 0; /* 1 */
  overflow: visible; /* 2 */
}
/**
 * Add the correct display in IE.
 */
main {
  display: block;
}
/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
pre {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}
/* Text-level semantics
 * ========================================================================== */
/**
 * Add the correct text decoration in Edge 18-, IE, and Safari.
 */
abbr[title] {
  text-decoration: underline;
  text-decoration: underline dotted;
}
/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */
b,
strong {
  font-weight: bolder;
}
/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
code,
kbd,
samp {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}
/**
 * Add the correct font size in all browsers.
 */
small {
  font-size: 80%;
}
/* Embedded content
 * ========================================================================== */
/**
 * Hide the overflow in IE.
 */
svg:not(:root) {
  overflow: hidden;
}
/* Forms
 * ========================================================================== */
/**
 * Remove the margin on controls in Safari.
 */
button,
input,
select {
  margin: 0;
}
/**
 * 1. Show the overflow in IE.
 * 2. Remove the inheritance of text transform in Edge 18-, Firefox, and IE.
 */
button {
  overflow: visible; /* 1 */
  text-transform: none; /* 2 */
}
/**
 * Correct the inability to style buttons in iOS and Safari.
 */
button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}
/**
 * Correct the padding in Firefox.
 */
fieldset {
  padding: 0.35em 0.75em 0.625em;
}
/**
 * Show the overflow in Edge 18- and IE.
 */
input {
  overflow: visible;
}
/**
 * 1. Correct the text wrapping in Edge 18- and IE.
 * 2. Correct the color inheritance from `fieldset` elements in IE.
 */
legend {
  box-sizing: border-box; /* 1 */
  color: inherit; /* 2 */
  display: table; /* 1 */
  max-width: 100%; /* 1 */
  white-space: normal; /* 1 */
}
/**
 * 1. Add the correct display in Edge 18- and IE.
 * 2. Add the correct vertical alignment in Chrome, Edge, and Firefox.
 */
progress {
  display: inline-block; /* 1 */
  vertical-align: baseline; /* 2 */
}
/**
 * Remove the inheritance of text transform in Firefox.
 */
select {
  text-transform: none;
}
/**
 * 1. Remove the margin in Firefox and Safari.
 * 2. Remove the default vertical scrollbar in IE.
 */
textarea {
  margin: 0; /* 1 */
  overflow: auto; /* 2 */
}
/**
 * 1. Correct the odd appearance in Chrome, Edge, and Safari.
 * 2. Correct the outline style in Safari.
 */
[type="search"] {
  -webkit-appearance: textfield; /* 1 */
  outline-offset: -2px; /* 2 */
}
/**
 * Correct the cursor style of increment and decrement buttons in Safari.
 */
::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto;
}
/**
 * Correct the text style of placeholders in Chrome, Edge, and Safari.
 */
::-webkit-input-placeholder {
  color: inherit;
  opacity: 0.54;
}
/**
 * Remove the inner padding in Chrome, Edge, and Safari on macOS.
 */
::-webkit-search-decoration {
  -webkit-appearance: none;
}
/**
 * 1. Correct the inability to style upload buttons in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */
::-webkit-file-upload-button {
  -webkit-appearance: button; /* 1 */
  font: inherit; /* 2 */
}
/**
 * Remove the inner border and padding of focus outlines in Firefox.
 */
::-moz-focus-inner {
  border-style: none;
  padding: 0;
}
/**
 * Restore the focus outline styles unset by the previous rule in Firefox.
 */
:-moz-focusring {
  outline: 1px dotted ButtonText;
}
/**
 * Remove the additional :invalid styles in Firefox.
 */
:-moz-ui-invalid {
  box-shadow: none;
}
/* Interactive
 * ========================================================================== */
/*
 * Add the correct display in Edge 18- and IE.
 */
details {
  display: block;
}
/*
 * Add the correct styles in Edge 18-, IE, and Safari.
 */
dialog {
  background-color: white;
  border: solid;
  color: black;
  display: block;
  height: -moz-fit-content;
  height: -webkit-fit-content;
  height: fit-content;
  left: 0;
  margin: auto;
  padding: 1em;
  position: absolute;
  right: 0;
  width: -moz-fit-content;
  width: -webkit-fit-content;
  width: fit-content;
}
dialog:not([open]) {
  display: none;
}
/*
 * Add the correct display in all browsers.
 */
summary {
  display: list-item;
}
/* Scripting
 * ========================================================================== */
/**
 * Add the correct display in IE.
 */
template {
  display: none;
}
/* User interaction
 * ========================================================================== */
* {
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
}

*:focus {
    outline: none;
}

a {
    text-decoration: none;
}

a:visited {
    color: rgb(0, 102, 204)
}

a:hover {
    text-decoration: underline;
}

ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

span {
    display: block;
}

body {
    margin: 0;
    font-family: 'Roboto', sans-serif;
    position: relative;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

input,
button,
textarea {
    font-family: 'Roboto', sans-serif;
}

.ReactModal__Html--open {
    overflow: hidden;
}

.container {
    max-width: 1200px;
    margin: 0 auto;
    height: 100vh;
    position: relative;
}

.cover__image {
    background-image: url(/static/media/bg_compressed.7b909746.jpg);
    background-position: center top;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
}
.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
  margin-left: -8px;
  position: absolute;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before {
  box-sizing: content-box;
  position: absolute;
  border: 8px solid transparent;
  height: 0;
  width: 1px;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before {
  content: "";
  z-index: -1;
  border-width: 8px;
  left: -8px;
  border-bottom-color: #aeaeae;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
  top: 0;
  margin-top: -8px;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
  border-top: none;
  border-bottom-color: #f0f0f0;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
  top: -1px;
  border-bottom-color: #aeaeae;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
  bottom: 0;
  margin-bottom: -8px;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before {
  border-bottom: none;
  border-top-color: #fff;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before {
  bottom: -1px;
  border-top-color: #aeaeae;
}

.react-datepicker-wrapper {
  display: inline-block;
  padding: 0;
  border: 0;
}

.react-datepicker {
  font-family: 'Roboto', sans-serif;
  font-size: 0.8rem;
  background-color: #fff;
  color: #000;
  border: 1px solid #aeaeae;
  border-radius: 0.3rem;
  display: inline-block;
  position: relative;
}

.react-datepicker--time-only .react-datepicker__triangle {
  left: 35px;
}

.react-datepicker--time-only .react-datepicker__time-container {
  border-left: 0;
}

.react-datepicker--time-only .react-datepicker__time, .react-datepicker--time-only .react-datepicker__time-box {
  border-bottom-left-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.react-datepicker__triangle {
  position: absolute;
  left: 50px;
}

.react-datepicker-popper {
  z-index: 1;
}

.react-datepicker-popper[data-placement^="bottom"] {
  margin-top: 10px;
}

.react-datepicker-popper[data-placement="bottom-end"] .react-datepicker__triangle, .react-datepicker-popper[data-placement="top-end"] .react-datepicker__triangle {
  left: auto;
  right: 50px;
}

.react-datepicker-popper[data-placement^="top"] {
  margin-bottom: 10px;
}

.react-datepicker-popper[data-placement^="right"] {
  margin-left: 8px;
}

.react-datepicker-popper[data-placement^="right"] .react-datepicker__triangle {
  left: auto;
  right: 42px;
}

.react-datepicker-popper[data-placement^="left"] {
  margin-right: 8px;
}

.react-datepicker-popper[data-placement^="left"] .react-datepicker__triangle {
  left: 42px;
  right: auto;
}

.react-datepicker__header {
  text-align: center;
  border-top-left-radius: 0.3rem;
  padding-top: 8px;
  position: relative;
}

.react-datepicker__header--time {
  padding-bottom: 8px;
  padding-left: 5px;
  padding-right: 5px;
}

.react-datepicker__header--time:not(.react-datepicker__header--time--only) {
  border-top-left-radius: 0;
}

.react-datepicker__header:not(.react-datepicker__header--has-time-select) {
  border-top-right-radius: 0.3rem;
}

.react-datepicker__year-dropdown-container--select,
.react-datepicker__month-dropdown-container--select,
.react-datepicker__month-year-dropdown-container--select,
.react-datepicker__year-dropdown-container--scroll,
.react-datepicker__month-dropdown-container--scroll,
.react-datepicker__month-year-dropdown-container--scroll {
  display: inline-block;
  margin: 0 2px;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  margin-top: 0;
  color: #000;
  font-weight: bold;
  font-size: 0.944rem;
}

.react-datepicker-time__header {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.react-datepicker__navigation {
  background: none;
  line-height: 1.7rem;
  text-align: center;
  cursor: pointer;
  position: absolute;
  top: 10px;
  padding: 0;
  border: 0.45rem solid transparent;
  z-index: 1;
  height: 10px;
  width: 10px;
  text-indent: -999em;
  overflow: hidden;
}

.react-datepicker__navigation--previous {
  left: 10px;
  border-right-color: #ccc;
}

.react-datepicker__navigation--previous:hover {
  border-right-color: #b3b3b3;
}

.react-datepicker__navigation--previous--disabled, .react-datepicker__navigation--previous--disabled:hover {
  border-right-color: #e6e6e6;
  cursor: default;
}

.react-datepicker__navigation--next {
  right: 10px;
  border-left-color: #ccc;
}

.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
  right: 95px;
}

.react-datepicker__navigation--next:hover {
  border-left-color: #b3b3b3;
}

.react-datepicker__navigation--next--disabled, .react-datepicker__navigation--next--disabled:hover {
  border-left-color: #e6e6e6;
  cursor: default;
}

.react-datepicker__navigation--years {
  position: relative;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.react-datepicker__navigation--years-previous {
  top: 4px;
  border-top-color: #ccc;
}

.react-datepicker__navigation--years-previous:hover {
  border-top-color: #b3b3b3;
}

.react-datepicker__navigation--years-upcoming {
  top: -4px;
  border-bottom-color: #ccc;
}

.react-datepicker__navigation--years-upcoming:hover {
  border-bottom-color: #b3b3b3;
}

.react-datepicker__month-container {
  width: 100%;
}

.react-datepicker__year {
  margin: 0.4rem;
  text-align: center;
}

.react-datepicker__year-wrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  max-width: 180px;
}

.react-datepicker__year .react-datepicker__year-text {
  display: inline-block;
  width: 4rem;
  margin: 2px;
}

.react-datepicker__month {
  text-align: center;
  margin: 10px 0 0;
}

.react-datepicker__month .react-datepicker__month-text,
.react-datepicker__month .react-datepicker__quarter-text {
  display: inline-block;
  width: 4rem;
  margin: 2px;
}

.react-datepicker__input-time-container {
  clear: both;
  width: 100%;
  float: left;
  margin: 5px 0 10px 15px;
  text-align: left;
}

.react-datepicker__input-time-container .react-datepicker-time__caption {
  display: inline-block;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container {
  display: inline-block;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input {
  display: inline-block;
  margin-left: 10px;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input {
  width: 85px;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type="time"]::-webkit-inner-spin-button,
.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type="time"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type="time"] {
  -moz-appearance: textfield;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__delimiter {
  margin-left: 5px;
  display: inline-block;
}

.react-datepicker__time-container {
  float: right;
  border-left: 1px solid #aeaeae;
  width: 85px;
}

.react-datepicker__time-container--with-today-button {
  display: inline;
  border: 1px solid #aeaeae;
  border-radius: 0.3rem;
  position: absolute;
  right: -72px;
  top: 0;
}

.react-datepicker__time-container .react-datepicker__time {
  position: relative;
  background: white;
  border-bottom-right-radius: 0.3rem;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
  width: 85px;
  overflow-x: hidden;
  margin: 0 auto;
  text-align: center;
  border-bottom-right-radius: 0.3rem;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
  list-style: none;
  margin: 0;
  height: calc(195px + (1.7rem / 2));
  overflow-y: scroll;
  padding-right: 0;
  padding-left: 0;
  width: 100%;
  box-sizing: content-box;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
  height: 30px;
  padding: 5px 10px;
  white-space: nowrap;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover {
  cursor: pointer;
  background-color: #f0f0f0;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
  background-color: #216ba5;
  color: white;
  font-weight: bold;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected:hover {
  background-color: #216ba5;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled {
  color: #ccc;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled:hover {
  cursor: default;
  background-color: transparent;
}

.react-datepicker__week-number {
  color: #ccc;
  display: inline-block;
  width: 1.7rem;
  line-height: 1.7rem;
  text-align: center;
  margin: 0.166rem;
}

.react-datepicker__week-number.react-datepicker__week-number--clickable {
  cursor: pointer;
}

.react-datepicker__week-number.react-datepicker__week-number--clickable:hover {
  border-radius: 0.3rem;
  background-color: #f0f0f0;
}

.react-datepicker__day-names,
.react-datepicker__week {
  white-space: nowrap;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding: 5px 0;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  color: #909090;
  width: 35px;
  height: 35px;
  line-height: 2rem;
  border: 2px solid #ECE9E9;
  border-radius: 3px;
  text-align: center;
}

.react-datepicker__month--selected, .react-datepicker__month--in-selecting-range, .react-datepicker__month--in-range,
.react-datepicker__quarter--selected,
.react-datepicker__quarter--in-selecting-range,
.react-datepicker__quarter--in-range {
  border-color: #2D9CDB;
  color: #2D9CDB;
}

.react-datepicker__month--selected:hover, .react-datepicker__month--in-selecting-range:hover, .react-datepicker__month--in-range:hover,
.react-datepicker__quarter--selected:hover,
.react-datepicker__quarter--in-selecting-range:hover,
.react-datepicker__quarter--in-range:hover {
  background-color: #1d5d90;
}

.react-datepicker__month--disabled,
.react-datepicker__quarter--disabled {
  color: #ccc;
  pointer-events: none;
}

.react-datepicker__month--disabled:hover,
.react-datepicker__quarter--disabled:hover {
  cursor: default;
  background-color: transparent;
}

.react-datepicker__day,
.react-datepicker__month-text,
.react-datepicker__quarter-text,
.react-datepicker__year-text {
  cursor: pointer;
  transition: all .1s ease-in;
}

.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
  border-radius: 3px;
  border-color: #cccccc;
}

.react-datepicker__day:active,
.react-datepicker__month-text:active,
.react-datepicker__quarter-text:active,
.react-datepicker__year-text:active {
  border-color: #2A75DB;
  color: #2A75DB;
  background-color: transparent;
  -webkit-transform: scale(.95);
          transform: scale(.95);
}

.react-datepicker__day--selected:active {
  -webkit-transform: none;
          transform: none;
  color: #2D9CDB;
}

.react-datepicker__day--today,
.react-datepicker__month-text--today,
.react-datepicker__quarter-text--today,
.react-datepicker__year-text--today {
  border-color: #D8D8D8;
}

.react-datepicker__day--highlighted,
.react-datepicker__month-text--highlighted,
.react-datepicker__quarter-text--highlighted,
.react-datepicker__year-text--highlighted {
  border-radius: 0.3rem;
  background-color: #3dcc4a;
  color: #fff;
}

.react-datepicker__day--highlighted:hover,
.react-datepicker__month-text--highlighted:hover,
.react-datepicker__quarter-text--highlighted:hover,
.react-datepicker__year-text--highlighted:hover {
  background-color: #32be3f;
}

.react-datepicker__day--highlighted-custom-1,
.react-datepicker__month-text--highlighted-custom-1,
.react-datepicker__quarter-text--highlighted-custom-1,
.react-datepicker__year-text--highlighted-custom-1 {
  color: magenta;
}

.react-datepicker__day--highlighted-custom-2,
.react-datepicker__month-text--highlighted-custom-2,
.react-datepicker__quarter-text--highlighted-custom-2,
.react-datepicker__year-text--highlighted-custom-2 {
  color: green;
}

.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  border-color: #2D9CDB;
  color: #2D9CDB;
}

.react-datepicker__day--selected:hover, .react-datepicker__day--in-selecting-range:hover, .react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__quarter-text--selected:hover,
.react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker__quarter-text--in-range:hover,
.react-datepicker__year-text--selected:hover,
.react-datepicker__year-text--in-selecting-range:hover,
.react-datepicker__year-text--in-range:hover {
  border-color: #2D9CDB;
  background-color: transparent;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  border-color: #2D9CDB;
  color: #2D9CDB;
}

.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__month-text--keyboard-selected:hover,
.react-datepicker__quarter-text--keyboard-selected:hover,
.react-datepicker__year-text--keyboard-selected:hover {
  background-color: transparent;
}


.react-datepicker__day--in-selecting-range ,
.react-datepicker__month-text--in-selecting-range ,
.react-datepicker__quarter-text--in-selecting-range ,
.react-datepicker__year-text--in-selecting-range {
  background-color: rgba(33, 107, 165, 0.5);
}

.react-datepicker__month--selecting-range .react-datepicker__day--in-range, .react-datepicker__month--selecting-range,
.react-datepicker__month-text--in-range , .react-datepicker__month--selecting-range,
.react-datepicker__quarter-text--in-range , .react-datepicker__month--selecting-range,
.react-datepicker__year-text--in-range {
  background-color: #f0f0f0;
  color: #909090;
}

.react-datepicker__day--outside-month {
  opacity: 0;
  visibility: hidden;
}

.react-datepicker__day--outside-month:hover {
  background-color: #f0f0f0;
  color: #909090;
  border-color: #eee;
}

.react-datepicker__day--disabled,
.react-datepicker__month-text--disabled,
.react-datepicker__quarter-text--disabled,
.react-datepicker__year-text--disabled {
  cursor: default;
  color: #F2F2F2;
  border-color: #F2F2F2;
  background-color: transparent;
}

.react-datepicker__day--disabled:hover,
.react-datepicker__month-text--disabled:hover,
.react-datepicker__quarter-text--disabled:hover,
.react-datepicker__year-text--disabled:hover {
  background-color: transparent;
  border-color: #F2F2F2;
}

.react-datepicker__day--disabled:active,
.react-datepicker__month-text--disabled:active,
.react-datepicker__quarter-text--disabled:active,
.react-datepicker__year-text--disabled:active {
  background-color: transparent;
  border-color: #F2F2F2;
  color: #F2F2F2;
  -webkit-transform: none;
          transform: none;
}

.react-datepicker__month-text.react-datepicker__month--selected:hover, .react-datepicker__month-text.react-datepicker__month--in-range:hover, .react-datepicker__month-text.react-datepicker__quarter--selected:hover, .react-datepicker__month-text.react-datepicker__quarter--in-range:hover,
.react-datepicker__quarter-text.react-datepicker__month--selected:hover,
.react-datepicker__quarter-text.react-datepicker__month--in-range:hover,
.react-datepicker__quarter-text.react-datepicker__quarter--selected:hover,
.react-datepicker__quarter-text.react-datepicker__quarter--in-range:hover {
  background-color: #216ba5;
}

.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover {
  background-color: #f0f0f0;
}

.react-datepicker__input-container {
  position: relative;
  display: inline-block;
  width: 100%;
}

.react-datepicker__year-read-view,
.react-datepicker__month-read-view,
.react-datepicker__month-year-read-view {
  border: 1px solid transparent;
  border-radius: 0.3rem;
}

.react-datepicker__year-read-view:hover,
.react-datepicker__month-read-view:hover,
.react-datepicker__month-year-read-view:hover {
  cursor: pointer;
}

.react-datepicker__year-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__year-read-view:hover .react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view:hover .react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-year-read-view:hover .react-datepicker__month-read-view--down-arrow {
  border-top-color: #b3b3b3;
}

.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
  border-top-color: #ccc;
  float: right;
  margin-left: 20px;
  top: 8px;
  position: relative;
  border-width: 0.45rem;
}

.react-datepicker__year-dropdown,
.react-datepicker__month-dropdown,
.react-datepicker__month-year-dropdown {
  background-color: #f0f0f0;
  position: absolute;
  width: 50%;
  left: 25%;
  top: 30px;
  z-index: 1;
  text-align: center;
  border-radius: 0.3rem;
  border: 1px solid #aeaeae;
}

.react-datepicker__year-dropdown:hover,
.react-datepicker__month-dropdown:hover,
.react-datepicker__month-year-dropdown:hover {
  cursor: pointer;
}

.react-datepicker__year-dropdown--scrollable,
.react-datepicker__month-dropdown--scrollable,
.react-datepicker__month-year-dropdown--scrollable {
  height: 150px;
  overflow-y: scroll;
}

.react-datepicker__year-option,
.react-datepicker__month-option,
.react-datepicker__month-year-option {
  line-height: 20px;
  width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.react-datepicker__year-option:first-of-type,
.react-datepicker__month-option:first-of-type,
.react-datepicker__month-year-option:first-of-type {
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}

.react-datepicker__year-option:last-of-type,
.react-datepicker__month-option:last-of-type,
.react-datepicker__month-year-option:last-of-type {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-bottom-left-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.react-datepicker__year-option:hover,
.react-datepicker__month-option:hover,
.react-datepicker__month-year-option:hover {
  background-color: #ccc;
}

.react-datepicker__year-option:hover .react-datepicker__navigation--years-upcoming,
.react-datepicker__month-option:hover .react-datepicker__navigation--years-upcoming,
.react-datepicker__month-year-option:hover .react-datepicker__navigation--years-upcoming {
  border-bottom-color: #b3b3b3;
}

.react-datepicker__year-option:hover .react-datepicker__navigation--years-previous,
.react-datepicker__month-option:hover .react-datepicker__navigation--years-previous,
.react-datepicker__month-year-option:hover .react-datepicker__navigation--years-previous {
  border-top-color: #b3b3b3;
}

.react-datepicker__year-option--selected,
.react-datepicker__month-option--selected,
.react-datepicker__month-year-option--selected {
  position: absolute;
  left: 15px;
}

.react-datepicker__close-icon {
  cursor: pointer;
  background-color: transparent;
  border: 0;
  outline: 0;
  padding: 0 6px 0 0;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  display: table-cell;
  vertical-align: middle;
}

.react-datepicker__close-icon::after {
  cursor: pointer;
  background-color: #216ba5;
  color: #fff;
  border-radius: 50%;
  height: 16px;
  width: 16px;
  padding: 2px;
  font-size: 12px;
  line-height: 1;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
  content: "\00d7";
}

.react-datepicker__today-button {
  background: #f0f0f0;
  border-top: 1px solid #aeaeae;
  cursor: pointer;
  text-align: center;
  font-weight: bold;
  padding: 5px 0;
  clear: left;
}

.react-datepicker__portal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  left: 0;
  top: 0;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  display: -webkit-flex;
  display: flex;
  z-index: 2147483647;
}

.react-datepicker__portal .react-datepicker__day-name,
.react-datepicker__portal .react-datepicker__day,
.react-datepicker__portal .react-datepicker__time-name {
  width: 3rem;
  line-height: 3rem;
}

@media (max-width: 400px), (max-height: 550px) {
  .react-datepicker__portal .react-datepicker__day-name,
  .react-datepicker__portal .react-datepicker__day,
  .react-datepicker__portal .react-datepicker__time-name {
    width: 2rem;
    line-height: 2rem;
  }
}

.react-datepicker__portal .react-datepicker__current-month,
.react-datepicker__portal .react-datepicker-time__header {
  font-size: 1.44rem;
}

.react-datepicker__portal .react-datepicker__navigation {
  border: 0.81rem solid transparent;
}

.react-datepicker__portal .react-datepicker__navigation--previous {
  border-right-color: #ccc;
}

.react-datepicker__portal .react-datepicker__navigation--previous:hover {
  border-right-color: #b3b3b3;
}

.react-datepicker__portal .react-datepicker__navigation--previous--disabled, .react-datepicker__portal .react-datepicker__navigation--previous--disabled:hover {
  border-right-color: #e6e6e6;
  cursor: default;
}

.react-datepicker__portal .react-datepicker__navigation--next {
  border-left-color: #ccc;
}

.react-datepicker__portal .react-datepicker__navigation--next:hover {
  border-left-color: #b3b3b3;
}

.react-datepicker__portal .react-datepicker__navigation--next--disabled, .react-datepicker__portal .react-datepicker__navigation--next--disabled:hover {
  border-left-color: #e6e6e6;
  cursor: default;
}

.Button_default__1v7zv {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  cursor: pointer;
  font-size: .875rem;
  font-weight: 500;
  height: 40px;
  padding: 0 30px;
  color: #444444;
  background: #FFFFFF;
  border-radius: 2px;
  border: 1px solid;
  transition: all .15s ease-in, color .15s ease-in, border-color .15s ease-in;
}

.Button_default__1v7zv:hover {
  color: #FFFFFF;
  background: #444444;
  border-color: #444444;
}

.Button_default__1v7zv:focus {
  outline: none;
}

.Button_default__1v7zv:active {
  -webkit-transform: translateY(1px);
          transform: translateY(1px);
}

.Button_default__1v7zv:disabled {
  cursor: default;
  color: #D8D8D8;
  border-color: #D8D8D8;
}

.Button_default__1v7zv:disabled:hover {
  color: #D8D8D8;
  background: transparent;
  text-decoration: none;
}

.Button_solid__2wbg- {
  color: #FFFFFF;
  background: #2F80ED;
  border-color: #2F80ED;
}

.Button_solid__2wbg-:hover {
  background: #2A75DB;
  border-color: #2A75DB;
}

.Button_solid__2wbg-:active {
  background: #2F80ED;
}

.Button_solid__2wbg-:disabled:hover {
  color: #D8D8D8;
  background: #2F80ED;
  text-decoration: none;
}

@-webkit-keyframes SmallLoader_small-loader__3muva {
  0% {
    opacity: 1
  }
  100% {
    opacity: 0
  }
}

@keyframes SmallLoader_small-loader__3muva {
  0% {
    opacity: 1
  }
  100% {
    opacity: 0
  }
}

.SmallLoader_small-loader__3muva div {
  left: 47px;
  top: 24px;
  position: absolute;
  -webkit-animation: SmallLoader_small-loader__3muva linear 1s infinite;
          animation: SmallLoader_small-loader__3muva linear 1s infinite;
  background: #FFFFFF;
  width: 6px;
  height: 12px;
  border-radius: 3px / 6px;
  -webkit-transform-origin: 3px 26px;
          transform-origin: 3px 26px;
}

.SmallLoader_small-loader__3muva div:nth-child(1) {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
  -webkit-animation-delay: -0.9230769230769231s;
          animation-delay: -0.9230769230769231s;
  background: #FFFFFF;
}

.SmallLoader_small-loader__3muva div:nth-child(2) {
  -webkit-transform: rotate(27.692307692307693deg);
          transform: rotate(27.692307692307693deg);
  -webkit-animation-delay: -0.8461538461538461s;
          animation-delay: -0.8461538461538461s;
  background: #FFFFFF;
}

.SmallLoader_small-loader__3muva div:nth-child(3) {
  -webkit-transform: rotate(55.38461538461539deg);
          transform: rotate(55.38461538461539deg);
  -webkit-animation-delay: -0.7692307692307693s;
          animation-delay: -0.7692307692307693s;
  background: #FFFFFF;
}

.SmallLoader_small-loader__3muva div:nth-child(4) {
  -webkit-transform: rotate(83.07692307692308deg);
          transform: rotate(83.07692307692308deg);
  -webkit-animation-delay: -0.6923076923076923s;
          animation-delay: -0.6923076923076923s;
  background: #FFFFFF;
}

.SmallLoader_small-loader__3muva div:nth-child(5) {
  -webkit-transform: rotate(110.76923076923077deg);
          transform: rotate(110.76923076923077deg);
  -webkit-animation-delay: -0.6153846153846154s;
          animation-delay: -0.6153846153846154s;
  background: #FFFFFF;
}

.SmallLoader_small-loader__3muva div:nth-child(6) {
  -webkit-transform: rotate(138.46153846153845deg);
          transform: rotate(138.46153846153845deg);
  -webkit-animation-delay: -0.5384615384615384s;
          animation-delay: -0.5384615384615384s;
  background: #FFFFFF;
}

.SmallLoader_small-loader__3muva div:nth-child(7) {
  -webkit-transform: rotate(166.15384615384616deg);
          transform: rotate(166.15384615384616deg);
  -webkit-animation-delay: -0.46153846153846156s;
          animation-delay: -0.46153846153846156s;
  background: #FFFFFF;
}

.SmallLoader_small-loader__3muva div:nth-child(8) {
  -webkit-transform: rotate(193.84615384615384deg);
          transform: rotate(193.84615384615384deg);
  -webkit-animation-delay: -0.38461538461538464s;
          animation-delay: -0.38461538461538464s;
  background: #FFFFFF;
}

.SmallLoader_small-loader__3muva div:nth-child(9) {
  -webkit-transform: rotate(221.53846153846155deg);
          transform: rotate(221.53846153846155deg);
  -webkit-animation-delay: -0.3076923076923077s;
          animation-delay: -0.3076923076923077s;
  background: #FFFFFF;
}

.SmallLoader_small-loader__3muva div:nth-child(10) {
  -webkit-transform: rotate(249.23076923076923deg);
          transform: rotate(249.23076923076923deg);
  -webkit-animation-delay: -0.23076923076923078s;
          animation-delay: -0.23076923076923078s;
  background: #FFFFFF;
}

.SmallLoader_small-loader__3muva div:nth-child(11) {
  -webkit-transform: rotate(276.9230769230769deg);
          transform: rotate(276.9230769230769deg);
  -webkit-animation-delay: -0.15384615384615385s;
          animation-delay: -0.15384615384615385s;
  background: #FFFFFF;
}

.SmallLoader_small-loader__3muva div:nth-child(12) {
  -webkit-transform: rotate(304.61538461538464deg);
          transform: rotate(304.61538461538464deg);
  -webkit-animation-delay: -0.07692307692307693s;
          animation-delay: -0.07692307692307693s;
  background: #FFFFFF;
}

.SmallLoader_small-loader__3muva div:nth-child(13) {
  -webkit-transform: rotate(332.3076923076923deg);
          transform: rotate(332.3076923076923deg);
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
  background: #FFFFFF;
}

.SmallLoader_small-loader-wrapper__2splZ {
  width: 32px;
  height: 32px;
  display: block;
  background: none;
}

.SmallLoader_small-loader__3muva {
  width: 100%;
  height: 100%;
  position: relative;
  -webkit-transform: translateZ(0) scale(0.32);
          transform: translateZ(0) scale(0.32);
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0; /* see note above */
}

.SmallLoader_small-loader__3muva div {
  box-sizing: content-box;
}
.SignUpForm_title__1maFC {
  color: #3E3E3E;
  font-weight: 500;
  font-size: .875rem;
  margin-top: 0;
}

.SignUpForm_row__39ykj {
  display: -webkit-flex;
  display: flex;
  padding: 6px 0;
}

.SignUpForm_col__mUFwu {
  width: 100%;
  padding: 0 10px;
}

.SignUpForm_col__mUFwu:first-child {
  padding-left: 0;
}

.SignUpForm_col__mUFwu:last-child {
  padding-right: 0;
}

.SignUpForm_text__MdfQZ {
  font-size: .75rem;
  color: #565656;
  line-height: 1.4;
  margin: 8px 0;
}

.SignUpForm_submit__2-g0V {
  width: 100%;
  margin-top: 15px;
}

.SignUpForm_underline__2HFyK {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  padding: 12px 0;
  margin-top: 15px;
  border-top: 1px solid #B1B1B1;
}

.SignUpForm_underline__link__5Qm9L {
  cursor: pointer;
  display: block;
  padding: 1px 0;
  font-size: .75rem;
  color: #2F80ED;
  border-bottom: 1px solid transparent;
}

.SignUpForm_underline__link__5Qm9L:hover {
  border-bottom: 1px solid #2F80ED;
  text-decoration: none;
}
.Main_wrapper__bePoM {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  padding: 100px 0 30px;
}

.Main_logo__29GKd {
  padding: 20px 25px;
}

.Main_bottom__1J2C_ {
  width: 450px;
  font-size: .75rem;
  margin: 0;
  color: #FFFFFF;
  line-height: 1.4;
}

.Main_paragraph__2YRgj {
  text-align: center;
}

.Main_link__ywq_F {
  font-weight: 500;
  padding: 1px 0;
  color: #F3F3F3;
  border-bottom: 1px solid;
}

.Main_link__ywq_F:hover {
  color: #FFFFFF;
  text-decoration: none;
  border-bottom: 1px solid transparent;
}

.Logo_logo__1Ev9K {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  text-shadow: 0 2px 6px rgba(0, 0, 0, 0.25);
}

.Logo_addon__2sHdq {
  font-size: x-large;
  color: #FFF;
  margin: -8px 0 0 10px;
}
.SignInForm_replaced__2ftOe {
  color: #CC0000;
  font-size: .75rem;
  margin: 0;
  padding: 0 0 10px;
}

.SignInForm_title___zEVz {
  color: #3E3E3E;
  font-weight: 500;
  font-size: .875rem;
  margin-top: 0;
}

.SignInForm_row__1FQex {
  display: -webkit-flex;
  display: flex;
  padding: 6px 0;
}

.SignInForm_col__1LDdv {
  width: 100%;
}

.SignInForm_col__1LDdv:first-child {
  padding-left: 0;
}

.SignInForm_col__1LDdv:last-child {
  padding-right: 0;
}

.SignInForm_checkboxLabel__12weH {
  cursor: pointer;
  padding: 10px 0;
  color: #8E8E8E;
  font-size: 13px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.SignInForm_checkbox__2s4-w {
  opacity: 0;
  position: absolute;
  z-index: -1;
  width: 16px;
  height: 16px;
  background: #F6F6F6;
  border: 1px solid #C4C4C4;
  border-radius: 2px;
  margin-right: 8px;
}

.SignInForm_checkbox__2s4-w + label {
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.SignInForm_checkbox__2s4-w + label:before {
  content: '';
  display: inline-block;
  width: 16px;
  height: 16px;
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
  -webkit-flex-grow: 0;
          flex-grow: 0;
  background-color: #F6F6F6;
  border: 1px solid #C4C4C4;
  border-radius: 2px;
  margin-right: 8px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
}

.SignInForm_checkbox__2s4-w:checked + label:before {
  border-color: #2F80ED;
  background-color: #2F80ED;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
}

.SignInForm_checkbox__2s4-w:not(:disabled):not(:checked)+label:hover:before {
  border-color: #b3d7ff;
}

.SignInForm_checkbox__2s4-w:not(:disabled):active+label:before {
  background-color: #b3d7ff;
  border-color: #b3d7ff;
}

.SignInForm_checkbox__2s4-w:disabled+label:before {
  background-color: #FAFAFA;
}

.SignInForm_submit__2TmmK {
  width: 100%;
  margin: 15px 0 20px;
}

.SignInForm_underline__1IsCy {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  padding: 12px 0;
  margin-top: 15px;
  border-top: 1px solid #B1B1B1;
}

.SignInForm_underline__link__1t1kB {
  display: block;
  padding: 1px 0;
  font-size: .75rem;
  color: #2F80ED;
  border-bottom: 1px solid transparent;
}

.SignInForm_underline__link__1t1kB:hover {
  border-bottom: 1px solid #2F80ED;
  text-decoration: none;
}

.CalendarTop_top__1ZUP5 {
  width: 100%;
  background-color: #FFFFFF;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.08);
}

.CalendarTop_modalOpen__3Z_hQ {
  padding-right: 17px;
}

.CalendarTop_headline__Wx9tb {
  padding: 8px 15px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  overflow: auto;
  white-space: nowrap;
}

.CalendarTop_left__2f9vn, .CalendarTop_right__2zxuQ {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
}

.CalendarTop_left__2f9vn {
  max-width: 300px;
}

.CalendarTop_monthSelector__3upkC {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
}

.CalendarTop_prevMonth__w3X1d, .CalendarTop_nextMonth__1BV0X {
  cursor: pointer;
}

.CalendarTop_prevMonth__w3X1d:before, .CalendarTop_nextMonth__1BV0X:before {
  content: '';
  border-width: 0.15em 0.15em 0 0;
  display: inline-block;
  border-style: solid;
  height: 0.4rem;
  -webkit-transform: rotate(-135deg);
          transform: rotate(-135deg);
  width: 0.4rem;
  margin: 1px 5px;
  transition: all .15s ease;
}

.CalendarTop_nextMonth__1BV0X:before {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}

.CalendarTop_prevMonth__w3X1d:hover:before {
  -webkit-transform: scale(1.1) rotate(-135deg);
          transform: scale(1.1) rotate(-135deg);
}

.CalendarTop_nextMonth__1BV0X:hover:before {
  -webkit-transform: scale(1.1) rotate(45deg);
          transform: scale(1.1) rotate(45deg);
}

.CalendarTop_prevMonth__w3X1d:active:before {
  -webkit-transform: scale(.9) rotate(-135deg);
          transform: scale(.9) rotate(-135deg);
}

.CalendarTop_nextMonth__1BV0X:active:before {
  -webkit-transform: scale(.9) rotate(45deg);
          transform: scale(.9) rotate(45deg);
}

.CalendarTop_currentMonth__2OHHf {
  min-width: 100px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: .875rem;
  font-weight: 500;
}

.CalendarTop_currentMonth__2OHHf p {
  margin: 0;
  padding: 0;
}

.CalendarTop_currentMonth__2OHHf p:first-letter {
  text-transform: uppercase;
}

.CalendarTop_topBtn__2MnP8 {
  height: 28px;
  padding: 0 16px;
  font-size: .75rem;
  margin: 0 25px;
}

.CalendarTop_currentProgram__2zDEG, .CalendarTop_eventBlockItem__2Tqex {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  height: 28px;
  padding: 0 10px;
}

.CalendarTop_event__3SqxR {
  display: -webkit-flex;
  display: flex;
}

.CalendarTop_eventBlockItem__2Tqex:last-child {
  padding-right: 0;
}

.CalendarTop_currentProgram__2zDEG p, .CalendarTop_eventBlockItem__2Tqex p{
  font-size: .625rem;
  font-weight: 500;
  text-transform: uppercase;
  color: #737373;
  padding: 0;
  margin: 0;
}

.CalendarTop_currentProgram__2zDEG span, .CalendarTop_eventBlockItem__2Tqex span {
  font-size: .75rem;
  font-weight: bold;
}

.CalendarTop_weekline__epfNo {
  border-top: 1px solid #E0E0E0;
}

.CalendarTop_weeklineList__1IpqE {
  display: -webkit-flex;
  display: flex;
}

.CalendarTop_weeklineListItem__3wyLn {
  width: 12.5%;
  padding: 10px 0 10px 15px;
  text-transform: uppercase;
  font-size: .625rem;
  color: #909090;
}


@media (max-width: 576px) {
  .CalendarTop_topBtn__2MnP8 {
    margin: 0 16px;
  }

  .CalendarTop_topBtn__2MnP8:first-child {
    margin-left: 0;
  }

  .CalendarTop_currentProgram__2zDEG {
    margin-right: 15px;
  }
}
.WeekHead_weekhead__2PHS5 {
  position: relative;
  width: 12.5%;
  min-height: 180px;
  padding: 13px 10px;
  background-color: #ECE9E9;
}

.WeekHead_weekhead__2PHS5:before {
  content: '';
  position: absolute;
  left: -10px;
  top: 0;
  height: 1px;
  width: calc(100% + 10px);
  background: linear-gradient(0.25turn,#D1D1D1, #ECE9E9);
}

.WeekHead_emptyWeek__2P7GV {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  text-align: center;
  margin: 0;
  height: 100%;
  color: #909090;
  font-size: .75rem;
}

.WeekHead_title__3CJvb {
  font-size: .75rem;
  font-weight: 500;
  padding: 0 0 2px;
}

.WeekHead_progress__1Xnis {
  padding: 10px 0;
}

.WeekHead_mark__16nOr {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.WeekHead_markName__1vlwc, .WeekHead_markValue__3cFMe {
  font-size: .675rem;
  padding: 2px 0;
}

.WeekHead_progressBar__F2yKs {
  padding: 5px 0;
  height: 26px;
}

.WeekHead_progressBar__F2yKs svg {
  width: 100%;
  max-height: 5px;
}
.Workout_closeBtn__2Bzoc {
  cursor: pointer;
  font-size: 2rem;
  background: none;
  border: none;
  padding: 0;
  line-height: .7;
}

.Workout_closeBtn__2Bzoc:hover {
  color: #535353;
}

.Workout_closeBtn__2Bzoc:active {
  -webkit-transform: scale(.95);
          transform: scale(.95);
}

.Workout_subtitle__3c2Za {
  font-size: .875rem;
  color: #444444;
  margin: 0;
  padding: 4px 0 8px;
}
.WorkoutChart_chart__2-Wda {
  max-width: 950px;
  cursor: pointer;
  margin: 0 -20px;
}
.CustomTooltip_tooltip__1CCDx {
  background-color: #FFFFFF;
  padding: 3px 12px;
  border: 1px solid #D8D8D8;
}

.CustomTooltip_label__wK23B {
  margin: 7px 0;
  font-size: .875rem;
}

.CustomTooltip_label__wK23B span {
  display: inline-block;
  font-weight: bold;
}
.ResetForm_title__3cskF {
  color: #3E3E3E;
  font-weight: 500;
  font-size: .875rem;
  margin-top: 0;
}

.ResetForm_row__2MOo3 {
  display: -webkit-flex;
  display: flex;
  padding: 6px 0;
}

.ResetForm_col__16-GQ {
  width: 100%;
  padding: 0 10px;
}

.ResetForm_col__16-GQ:first-child {
  padding-left: 0;
}

.ResetForm_col__16-GQ:last-child {
  padding-right: 0;
}

.ResetForm_text__Ivo69 {
  font-size: .75rem;
  color: #565656;
  line-height: 1.4;
  margin: 8px 0;
}

.ResetForm_submit__1VwPp {
  width: 100%;
  margin-top: 15px;
}

.ResetForm_underline__126id {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  padding: 12px 0;
  margin-top: 15px;
  border-top: 1px solid #B1B1B1;
}

.ResetForm_underline__link__20PsR {
  display: block;
  padding: 1px 0;
  font-size: .75rem;
  color: #2F80ED;
  border-bottom: 1px solid transparent;
}

.ResetForm_underline__link__20PsR:hover {
  border-bottom: 1px solid #2F80ED;
  text-decoration: none;
}
.NewPassword_title__2WpfW {
  color: #3E3E3E;
  font-weight: 500;
  font-size: .875rem;
  margin-top: 0;
}

.NewPassword_row__1g4K5 {
  display: -webkit-flex;
  display: flex;
  padding: 6px 0;
}

.NewPassword_col__2fHkX {
  width: 100%;
  padding: 0 10px;
}

.NewPassword_col__2fHkX:first-child {
  padding-left: 0;
}

.NewPassword_col__2fHkX:last-child {
  padding-right: 0;
}

.NewPassword_text__1aXNE {
  font-size: .75rem;
  color: #565656;
  line-height: 1.4;
  margin: 8px 0;
}

.NewPassword_submit__2JpSs {
  width: 100%;
  margin-top: 15px;
}

.NewPassword_underline__3o9lk {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  padding: 12px 0;
  margin-top: 15px;
  border-top: 1px solid #B1B1B1;
}

.NewPassword_underline__link__2q63L {
  display: block;
  padding: 1px 0;
  font-size: .75rem;
  color: #2F80ED;
  border-bottom: 1px solid transparent;
}

.NewPassword_underline__link__2q63L:hover {
  border-bottom: 1px solid #2F80ED;
  text-decoration: none;
}
.CodeForm_title__1LW1u {
  color: #3E3E3E;
  font-weight: 500;
  font-size: .875rem;
  margin-top: 0;
}

.CodeForm_row__33UsK {
  display: -webkit-flex;
  display: flex;
  padding: 6px 0;
}

.CodeForm_col__2Tw99 {
  width: 100%;
  padding: 0 10px;
}

.CodeForm_col__2Tw99:first-child {
  padding-left: 0;
}

.CodeForm_col__2Tw99:last-child {
  padding-right: 0;
}

.CodeForm_text__3g2AM {
  font-size: .75rem;
  color: #565656;
  line-height: 1.4;
  margin: 8px 0;
}

.CodeForm_submit__B2vQN {
  width: 100%;
  margin-top: 15px;
}

.CodeForm_underline__3UnbK {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  padding: 12px 0;
  margin-top: 15px;
  border-top: 1px solid #B1B1B1;
}

.CodeForm_underline__link__1KqxD {
  display: block;
  padding: 1px 0;
  font-size: .75rem;
  color: #2F80ED;
  border-bottom: 1px solid transparent;
}

.CodeForm_underline__link__1KqxD:hover {
  border-bottom: 1px solid #2F80ED;
  text-decoration: none;
}

.CodeForm_codeField__3wb-f input {
  text-transform: uppercase;
}

.CodeForm_codeField__3wb-f input::-webkit-input-placeholder {
  text-transform: none;
}

.CodeForm_codeField__3wb-f input:-ms-input-placeholder {
  text-transform: none;
}

.CodeForm_codeField__3wb-f input::placeholder {
  text-transform: none;
}
.Settings_emptyWrapper__xXalh {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  min-height: 100px;
  padding: 40px 0 30px;
}

.Settings_emptyWrapper__xXalh h2, .Settings_emptyWrapper__xXalh p {
  margin: 10px 0;
}

.Settings_checkboxLabel__2UhIB {
  cursor: pointer;
  padding: 10px 0;
  color: #8E8E8E;
  font-size: 13px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.Settings_submit__1pKTG {
  cursor: pointer;
  width: 100%;
  height: 37px;
  background: #219653;
  border-radius: 2px;
  border: none;
  color: #FFFFFF;
  font-size: .875rem;
  font-weight: bold;
  margin-top: 10px;
}

.Settings_submit__1pKTG:hover {
  background: #1d8045;
  box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.25);
}

.Settings_skeleton__1qg-N {
  margin: 8px 0;
}

.Settings_zoneItemHeader__17Ibg {
  padding-top: 15px;
}

.Settings_zoneItemHeader__17Ibg:first-child {
  padding-top: 0;
}

.Settings_zoneTitle__1qs1A {
  width: 100%;
  padding: 5px 0;
  border-bottom: 1px solid #D8D8D8;
  color: #3E3E3E;
  font-size: .875rem;
  font-weight: 500;
}


