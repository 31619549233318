.title {
  color: #3E3E3E;
  font-weight: 500;
  font-size: .875rem;
  margin-top: 0;
}

.row {
  display: flex;
  padding: 6px 0;
}

.col {
  width: 100%;
  padding: 0 10px;
}

.col:first-child {
  padding-left: 0;
}

.col:last-child {
  padding-right: 0;
}

.text {
  font-size: .75rem;
  color: #565656;
  line-height: 1.4;
  margin: 8px 0;
}

.submit {
  width: 100%;
  margin-top: 15px;
}

.underline {
  display: flex;
  justify-content: space-around;
  padding: 12px 0;
  margin-top: 15px;
  border-top: 1px solid #B1B1B1;
}

.underline__link {
  cursor: pointer;
  display: block;
  padding: 1px 0;
  font-size: .75rem;
  color: #2F80ED;
  border-bottom: 1px solid transparent;
}

.underline__link:hover {
  border-bottom: 1px solid #2F80ED;
  text-decoration: none;
}