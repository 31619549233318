.emptyWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100px;
  padding: 40px 0 30px;
}

.emptyWrapper h2, .emptyWrapper p {
  margin: 10px 0;
}

.checkboxLabel {
  cursor: pointer;
  padding: 10px 0;
  color: #8E8E8E;
  font-size: 13px;
  display: flex;
  align-items: center;
}

.submit {
  cursor: pointer;
  width: 100%;
  height: 37px;
  background: #219653;
  border-radius: 2px;
  border: none;
  color: #FFFFFF;
  font-size: .875rem;
  font-weight: bold;
  margin-top: 10px;
}

.submit:hover {
  background: #1d8045;
  box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.25);
}

.skeleton {
  margin: 8px 0;
}

.zoneItemHeader {
  padding-top: 15px;
}

.zoneItemHeader:first-child {
  padding-top: 0;
}

.zoneTitle {
  width: 100%;
  padding: 5px 0;
  border-bottom: 1px solid #D8D8D8;
  color: #3E3E3E;
  font-size: .875rem;
  font-weight: 500;
}

