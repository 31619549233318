.default {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: .875rem;
  font-weight: 500;
  height: 40px;
  padding: 0 30px;
  color: #444444;
  background: #FFFFFF;
  border-radius: 2px;
  border: 1px solid;
  transition: all .15s ease-in, color .15s ease-in, border-color .15s ease-in;
}

.default:hover {
  color: #FFFFFF;
  background: #444444;
  border-color: #444444;
}

.default:focus {
  outline: none;
}

.default:active {
  transform: translateY(1px);
}

.default:disabled {
  cursor: default;
  color: #D8D8D8;
  border-color: #D8D8D8;
}

.default:disabled:hover {
  color: #D8D8D8;
  background: transparent;
  text-decoration: none;
}

.solid {
  color: #FFFFFF;
  background: #2F80ED;
  border-color: #2F80ED;
}

.solid:hover {
  background: #2A75DB;
  border-color: #2A75DB;
}

.solid:active {
  background: #2F80ED;
}

.solid:disabled:hover {
  color: #D8D8D8;
  background: #2F80ED;
  text-decoration: none;
}
