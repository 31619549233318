.replaced {
  color: #CC0000;
  font-size: .75rem;
  margin: 0;
  padding: 0 0 10px;
}

.title {
  color: #3E3E3E;
  font-weight: 500;
  font-size: .875rem;
  margin-top: 0;
}

.row {
  display: flex;
  padding: 6px 0;
}

.col {
  width: 100%;
}

.col:first-child {
  padding-left: 0;
}

.col:last-child {
  padding-right: 0;
}

.checkboxLabel {
  cursor: pointer;
  padding: 10px 0;
  color: #8E8E8E;
  font-size: 13px;
  display: flex;
  align-items: center;
}

.checkbox {
  opacity: 0;
  position: absolute;
  z-index: -1;
  width: 16px;
  height: 16px;
  background: #F6F6F6;
  border: 1px solid #C4C4C4;
  border-radius: 2px;
  margin-right: 8px;
}

.checkbox + label {
  display: inline-flex;
  align-items: center;
  user-select: none;
}

.checkbox + label:before {
  content: '';
  display: inline-block;
  width: 16px;
  height: 16px;
  flex-shrink: 0;
  flex-grow: 0;
  background-color: #F6F6F6;
  border: 1px solid #C4C4C4;
  border-radius: 2px;
  margin-right: 8px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
}

.checkbox:checked + label:before {
  border-color: #2F80ED;
  background-color: #2F80ED;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
}

.checkbox:not(:disabled):not(:checked)+label:hover:before {
  border-color: #b3d7ff;
}

.checkbox:not(:disabled):active+label:before {
  background-color: #b3d7ff;
  border-color: #b3d7ff;
}

.checkbox:disabled+label:before {
  background-color: #FAFAFA;
}

.submit {
  width: 100%;
  margin: 15px 0 20px;
}

.underline {
  display: flex;
  justify-content: space-around;
  padding: 12px 0;
  margin-top: 15px;
  border-top: 1px solid #B1B1B1;
}

.underline__link {
  display: block;
  padding: 1px 0;
  font-size: .75rem;
  color: #2F80ED;
  border-bottom: 1px solid transparent;
}

.underline__link:hover {
  border-bottom: 1px solid #2F80ED;
  text-decoration: none;
}
